export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'name',
    sortable: false,
    sortField: 'name',
    label: 'field.name',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'username',
    sortable: false,
    sortField: 'username',
    label: 'field.username',
  },
  {
    key: 'turnover',
    sortable: false,
    sortField: '',
    label: 'field.turnover',
    tdClass: 'text-right',
  },
  {
    key: 'newWl',
    sortable: false,
    sortField: '',
    label: 'field.newWinLoss',
    tdClass: 'text-right',
  },
  {
    key: 'jackpot',
    sortable: false,
    sortField: '',
    label: 'field.jackpot',
    tdClass: 'text-right',
  },
  {
    key: 'newWlJackpot',
    sortable: false,
    sortField: '',
    label: 'field.newWlJackpot',
    tdClass: 'text-right',
  },
  {
    key: 'deposit',
    sortable: false,
    sortField: '',
    label: 'field.deposit',
    tdClass: 'text-right',
  },
  {
    key: 'withdrawal',
    sortable: false,
    sortField: '',
    label: 'field.withdraw',
    tdClass: 'text-right',
  },
  {
    key: 'balance',
    sortable: false,
    sortField: '',
    label: 'field.balance',
    tdClass: 'text-right',
  },
  {
    key: 'outstanding',
    sortable: false,
    sortField: '',
    label: 'field.outstanding',
    tdClass: 'text-center',
  },
  {
    key: 'status',
    sortable: false,
    sortField: '',
    label: 'field.status',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
