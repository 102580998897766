export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'invoiceNo',
    label: 'field.invoiceNo',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'name',
    label: 'field.name',
  },
  {
    key: 'userName',
    label: 'field.username',
  },
  {
    key: 'agentName',
    label: 'field.agentName',
  },
  {
    key: 'agentUserName',
    label: 'field.agentUsername',
  },
  {
    key: 'fightMatchNo',
    label: 'field.round',
  },
  {
    key: 'amount',
    label: 'field.amount',
  },
  {
    key: 'type',
    label: 'field.prize',
  },
  {
    key: 'createdAt',
    label: 'field.createDate',
  },
];
