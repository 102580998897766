<template>
  <b-modal
    id="modal-bet-history"
    cancel-variant="outline-secondary"
    :ok-title="$t('button.ok')"
    :cancel-title="$t('button.close')"
    centered
    :title="$t('general.betHistory')"
    size="xl"
    @hide="isShow = false"
  >
    <!-- Search Container Card -->
    <n-search-container
      :active="true"
      :show-header="false"
      @search="search"
      @reset="reset"
      v-if="searchFields.length"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #period="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              :ref="item.field.key"
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in item.field.options"
                :key="option.value"
                >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate("DD-MM-YYYY HH:mm:ss") }}
          </template>
          <template #cell(reportDate)="data">
            {{ data.item.reportDate | formatDate("DD-MM-YYYY") }}
          </template>
          <template #cell(amount)="data">
            <span
              :class="{
                'text-positive': data.item.amount >= 0,
                'text-danger': data.item.amount < 0,
              }"
            >
              {{ data.item.amount | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(winLose)="data">
            <span
              :class="{
                'text-positive': data.item.winLose >= 0,
                'text-danger': data.item.winLose < 0,
              }"
              v-if="data.item.winLose"
            >
              {{ data.item.winLose | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(payback)="data">
            <span
              :class="{
                'text-positive': data.item.payback >= 0,
                'text-danger': data.item.payback < 0,
              }"
            >
              {{ data.item.payback | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(game)> Lotto </template>
          <template #cell(betType)="data">
            <span class="text-capitalize">
              {{
                data.item.selected == "under" || data.item.selected == "over"
                  ? $t("general.underOver")
                  : $t("general.rangeNumber")
              }}
            </span>
          </template>
          <template #cell(selected)="data">
            <span :class="`text-${data.item.selected} text-capitalize`">
              {{ data.item.selected }}
            </span>
          </template>
          <template #cell(result)="data">
            <span :class="`text-${data.item.result} text-capitalize`">
              {{ data.item.result }}
            </span>
          </template>
          <template #custom-foot>
            <b-tr v-if="!loading">
              <b-th colspan="9"></b-th>
              <b-th>
                <span
                  :class="{
                    'text-positive': totalReport.amount >= 0,
                    'text-danger': totalReport.amount < 0,
                  }"
                >
                  {{ totalReport.amount | currency(totalReport.ccy) }}
                </span>
              </b-th>
              <b-th colspan="1"></b-th>
              <b-th colspan="1"></b-th>
              <b-th>
                <span
                  :class="{
                    'text-positive': totalReport.payoutAmount >= 0,
                    'text-danger': totalReport.payoutAmount < 0,
                  }"
                >
                  {{ totalReport.payoutAmount | currency(totalReport.ccy) }}
                </span>
              </b-th>
              <b-th>
                <span
                  :class="{
                    'text-positive': totalReport.payback >= 0,
                    'text-danger': totalReport.payback < 0,
                  }"
                >
                  {{ totalReport.payback | currency(totalReport.ccy) }}
                </span>
              </b-th>
              <b-th colspan="1"></b-th>
            </b-tr>
          </template>
        </n-table>
        <n-pagination
          ref="jackpotHistoryPagination"
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
  BTr,
  BTh,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import tableFields from "./tableField";
import searchInputs from "./searchInput";
import { calculateDatesByPeriod } from "@/libs/helper.js";
import moment from "moment";

const BetHistoryRepository = Repository.get("betHistory");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    BTr,
    BTh,
    BModal,
  },
  watch: {
    perPage(value) {
      this.list();
    },
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.toDate = dates[1];
      }
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      totalReport: {},
      params: {
        page: 1,
        order: "",
        sort: "",
        userId: "",
        startDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
        period: "today",
      },
      totalReport: {},
    };
  },
  methods: {
    show(params) {
      this.$bvModal.show("modal-bet-history");
      this.isShow = true;
      this.params = {
        ...params,
      };
      this.list();
    },
    hide() {
      this.isShow = false;
      this.$bvModal.hide("modal-bet-history");
    },
    list(page = 1) {
      this.params.page = page;
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.getData();
    },
    search(searchText) {
      this.params.search = searchText;
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.getData();
    },
    getData() {
      this.loading = true;
      BetHistoryRepository.index({
        ...this.params,
      })
        .then((response) => {
          let data = response.data.data.list;
          this.items = [...data];
          this.total = response.data.data.total;
          this.totalReport = {};
          this.loading = false;
          this.getTotal();
          this.$refs.jackpotHistoryPagination.setPaginationLabel();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getTotal() {
      BetHistoryRepository.total({
        ...this.params,
      }).then((response) => {
        let data = response.data.data;
        this.totalReport = {
          ...data,
        };
      });
    },
  },
  setup() {
    const fields = [...tableFields];
    const searchFields = [...searchInputs];
    return {
      fields,
      searchFields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
