export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'invoiceNo',
    label: 'field.invoiceNo',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'name',
    label: 'field.name',
  },
  {
    key: 'username',
    label: 'field.username',
  },
  {
    key: 'game',
    label: 'field.game',
  },
  {
    key: 'fightNo',
    label: 'field.round',
  },
  {
    key: 'result',
    label: 'field.result',
  },
  {
    key: 'betType',
    label: 'field.betType',
  },
  {
    key: 'selected',
    label: 'field.bet',
  },
  {
    key: 'amount',
    label: 'field.amount',
  },
  {
    key: 'payout',
    label: 'field.odd',
  },
  {
    key: 'payoutBonus',
    label: 'field.bonus',
  },
  {
    key: 'winLose',
    label: 'field.winLose',
  },
  {
    key: 'payback',
    label: 'field.payback',
  },
  {
    key: 'createdAt',
    label: 'field.createDate',
  },
];
